import { Title, useModal } from 'crunch-components';
import { FunctionComponent } from 'react';
import { Scope } from 'shared/models/configuration';
import { InventoryAllocation } from 'shared/models/inventoryAllocation';
import { InventoryAllocationReport } from 'shared/models/inventoryAllocationReport';
import LocationsTableModal from '../modals/LocationsTableModal';
import ProductsTableModal from '../modals/ProductsTableModal';
import {
	getLocationsScopeDescription,
	getProductsScopeDescription,
} from './ScopeDescription';

interface Props extends Scope {
	inventoryAllocationId: InventoryAllocation['id'];
	reportId: InventoryAllocationReport['id'];
	defaultFilters: any[];
}

const ScopeWizardSection: FunctionComponent<Props> = ({
	inventoryAllocationId,
	reportId,
	products,
	locations,
	defaultFilters,
}) => {
	const { open } = useModal();

	function openProductsModal(): void {
		open({
			modalNode: (
				<ProductsTableModal
					defaultFilters={defaultFilters}
					selectedProducts={products}
					inventoryAllocationId={inventoryAllocationId}
					reportId={reportId}
				/>
			),
			sizeClass: 'w-4/5',
		});
	}

	function openLocationsModal(): void {
		open({
			modalNode: (
				<LocationsTableModal
					selectedLocations={locations}
					inventoryAllocationId={inventoryAllocationId}
					reportId={reportId}
				/>
			),
			sizeClass: 'w-4/5',
		});
	}

	return (
		<div className="my-16">
			<Title type="section">Scope</Title>
			<p className="text-ca-gray max-w-lg pt-2 pb-8">
				The scope determines which products and locations will be included in
				the inventory allocation.
			</p>
			<div className="border-ca-silver border rounded-lg bg-white px-4 py-5 -ml-4 max-w-xl">
				<p className="text-ca-gray">
					Calculate the optimal inventory allocation for{' '}
					<button type="button" onClick={openProductsModal}>
						<strong className="text-ca-purple">
							{getProductsScopeDescription(products)}
						</strong>
					</button>{' '}
					over{' '}
					<button type="button" onClick={openLocationsModal}>
						<strong className="text-ca-purple">
							{getLocationsScopeDescription(locations)}
						</strong>
					</button>
					.
				</p>
			</div>
		</div>
	);
};

export default ScopeWizardSection;
