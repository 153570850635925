import { Button, Modal, Tooltip, useModal } from 'crunch-components';
import { FunctionComponent, useState } from 'react';
import { InventoryAllocation } from 'shared/models/inventoryAllocation';
import { InventoryAllocationReport } from 'shared/models/inventoryAllocationReport';
import { StockLocationDTO } from 'shared/models/schema';
import LocationsTable from '../components/LocationsTable';

interface Props {
	inventoryAllocationId: InventoryAllocation['id'];
	reportId: InventoryAllocationReport['id'];
	selectedLocations: StockLocationDTO['stock_location_id'][];
	setSelectedLocations?: (
		locations: StockLocationDTO['stock_location_id'][]
	) => void;
}

const LocationsTableModal: FunctionComponent<Props> = ({
	selectedLocations,
	setSelectedLocations,
	inventoryAllocationId,
	reportId,
}) => {
	const { close: rawClose } = useModal();
	const close = () => {
		if (rawClose !== undefined) {
			rawClose();
		}
	};
	const [shouldAskForConfirmation, setShouldAskForConfirmation] =
		useState<boolean>(false);
	const [askForConfirmation, setAskForConfirmation] = useState<boolean>(false);

	const onDone = () => {
		if (shouldAskForConfirmation) {
			setAskForConfirmation(true);
		} else {
			close();
		}
	};

	const onIAmSure = () => {
		setAskForConfirmation(false);
		close();
	};

	return (
		<div className="w-full">
			<div className="w-full bg-white rounded-2xl shadow-ca max-h-95vh overflow-y-scroll">
				<Modal.Content>
					<Modal.Title>Locations in scope</Modal.Title>
					<LocationsTable
						selectedLocations={selectedLocations}
						setSelectedLocations={setSelectedLocations}
						inventoryAllocationId={inventoryAllocationId}
						reportId={reportId}
						setShouldAskForConfirmation={setShouldAskForConfirmation}
						isReadOnly={!setSelectedLocations}
					/>
				</Modal.Content>
				<Modal.Actions className="flex justify-end">
					{!askForConfirmation && <Button onClick={onDone}>Done</Button>}
					{askForConfirmation && (
						<div className="flex gap-4">
							<Button
								onClick={() => setAskForConfirmation(false)}
								variant="secondary"
							>
								No, go back!
							</Button>
							<Tooltip
								isVisible
								content="Are you sure? You have not selected any locations yet!"
							>
								<Button onClick={onIAmSure}>Yes, I am sure!</Button>
							</Tooltip>
						</div>
					)}
				</Modal.Actions>
			</div>
		</div>
	);
};

export default LocationsTableModal;
