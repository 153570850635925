import cn from '../../utils/cn';

/* for text use h-4 */
function Skeleton({
	className,
	...props
}: React.HTMLAttributes<HTMLDivElement>) {
	return (
		<div
			className={cn('animate-pulse rounded-sm  bg-ca-silver', className)}
			{...props}
		/>
	);
}

export default Skeleton;
