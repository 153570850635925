import cn from '../../utils/cn';
import { PropsWithChildren } from 'react';
import Text from '../Text/Text';

const Title = (props: PropsWithChildren<{}>) => (
	<Text size="text-base" className="font-bold mb-8">
		{props.children}
	</Text>
);

const Content: React.FC<
	PropsWithChildren<{ className?: string; unstyled?: boolean }>
> = ({ className = '', children, unstyled = false }) => (
	<div className={cn(!unstyled && 'p-10', className)}>{children}</div>
);

const Actions = ({
	children,
	className = '',
	unstyled = false,
}: PropsWithChildren<{ className?: string; unstyled?: boolean }>) => {
	return (
		<div
			className={cn(
				!unstyled && 'py-5 px-10 rounded-b-[inherit] bg-ca-ghost-white',
				className
			)}
		>
			{children}
		</div>
	);
};

const Root = ({
	children,
	className = '',
	unstyled = false,
}: PropsWithChildren<{ className?: string; unstyled?: boolean }>) => {
	return (
		<div
			className={cn(
				!unstyled && 'max-w-lg bg-white rounded-lg shadow-2xl',
				className
			)}
		>
			{children}
		</div>
	);
};

export default { Root, Title, Content, Actions };
