import { useAuth0 } from '@auth0/auth0-react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

// Redirects to home page based on authentication status
export default function SmartHomeRedirect(props: {
	homePaths: { public: string; private: string };
}) {
	const location = useLocation();
	const { isAuthenticated, isLoading } = useAuth0();

	if (isLoading) {
		return <Outlet />;
	}

	const homePath = isAuthenticated
		? props.homePaths['private']
		: props.homePaths['public'];
	return <Navigate to={homePath} state={{from: location.pathname}} replace/>;
}
