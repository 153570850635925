import { LinearProgress, Title } from 'crunch-components';
import { FunctionComponent, useEffect } from 'react';
import { useQuery } from 'react-query';
import { Link, useParams } from 'react-router-dom';
import { GET_INVENTORY_ALLOCATION } from '../../../api/inventory-allocations';

import { useInventoryAllocations } from '../../../domains/domains';
import InventoryAllocationDetail from './InventoryAllocationDetail';

const InventoryAllocationView: FunctionComponent = () => {
	const { inventoryAllocationId } = useParams();

	if (inventoryAllocationId === undefined) {
		throw new Error(
			`[InventoryAllocationLayout] allocationId was undefined but this is impossible because this component/page should render based on the id in the url. Developer error.`,
		);
	}

	const {
		entity: inventoryAllocation,
		isLoading,
		refetch,
	} = useInventoryAllocations(
		useQuery(['inventory-allocations', inventoryAllocationId], () =>
			GET_INVENTORY_ALLOCATION(inventoryAllocationId),
		),
	);

	useEffect(() => {
		refetch();
	}, [inventoryAllocationId]);

	return (
		<>
			<div className="absolute left-32 right-0 top-0">
				{isLoading && <LinearProgress visible={isLoading} />}{' '}
				{/* TODO something wrong with LinearProgress, it doesn't stop because of something to do with mounting check */}
			</div>
			<div className="relative flex justify-between">
				<Title>
					{isLoading
						? 'Loading...'
						: inventoryAllocation?.title || 'Inventory allocation not found'}
				</Title>
			</div>
			<Link
				to="/stock/inventory-allocations"
				className="text-ca-gray hover:text-ca-purple hover:font-bold transition-colors"
			>
				{'<- Inventory Allocations'}
			</Link>
			{inventoryAllocation?.id ? (
				<InventoryAllocationDetail />
			) : (
				<div>no inventoryAllocation.id</div>
			)}
		</>
	);
};

export default InventoryAllocationView;
