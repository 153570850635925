import { Button, Dropdown } from 'crunch-components';
import { FunctionComponent, useState } from 'react';
import { QueryKey, useQueries, UseQueryOptions } from 'react-query';
import { Outlet, useLocation } from 'react-router-dom';
import { GET_INVENTORY_ALLOCATION_REPORT } from '../../../api/inventory-allocation-reports';
import ReportMapper from '../../../domains/reports/ReportMapper';

import { Configuration } from '../../../shared/models/configuration';
import { InventoryAllocation } from '../../../shared/models/inventoryAllocation';
import {
	InventoryAllocationReport,
	InventoryAllocationReportStatus,
} from '../../../shared/models/inventoryAllocationReport';
import { ReportDTO } from '../../../shared/models/schema';
import { DraftTabLink } from '../components/DraftTab';
import DraftTabs from '../components/DraftTabs';

interface Props {
	inventoryAllocationId: InventoryAllocation['id'];
	drafts: InventoryAllocationReport['id'][];
	isAddingDraft?: boolean;
	addDraft: (configuration?: Configuration) => void;
	submit: (report: InventoryAllocationReport) => void;
}

function toTab(
	inventoryAllocationId: InventoryAllocation['id'],
	draft: InventoryAllocationReport['id'],
): DraftTabLink {
	return {
		inventoryAllocationId,
		id: draft,
		to: draft, // Check if this works
	};
}

const toQuery = (
	inventoryAllocationId: InventoryAllocation['id'],
	id: InventoryAllocationReport['id'],
	queryKey: QueryKey,
): UseQueryOptions => ({
	queryKey,
	queryFn: () => GET_INVENTORY_ALLOCATION_REPORT(inventoryAllocationId, id),
});

const isProposal = (report: InventoryAllocationReport): boolean =>
	report.status === InventoryAllocationReportStatus.Proposal;

/** type definition for context object passed to <Outlet> */
export type InventoryAllocationOutletContext = {
	isAddingDraft?: boolean;
	addDraft: (configuration?: Configuration) => void;
};

const InventoryAllocationDrafts: FunctionComponent<Props> = ({
	inventoryAllocationId,
	drafts,
	isAddingDraft,
	addDraft,
	submit,
}) => {
	const mapper = new ReportMapper();
	const [selectedDraft, setSelectedDraft] = useState<
		InventoryAllocationReport | undefined
	>();
	const location = useLocation();
	const currentTab: string =
		location.pathname.match(/movements|results|configuration/)?.[0] || '';
	const tabs: DraftTabLink[] = drafts.map((draft) =>
		toTab(inventoryAllocationId, draft),
	);
	const reportsResult = useQueries(
		drafts.map((report) =>
			toQuery(inventoryAllocationId, report as string, ['reports', report]),
		),
	);

	const reports: InventoryAllocationReport[] = (
		reportsResult
			.map(({ data }) => data)
			.filter((report) => !!report) as ReportDTO[]
	).map(mapper.toReport);

	const toOption = (
		draft: InventoryAllocationReport,
	): { value: string; label: string } => ({
		value: draft.id,
		label: draft.title,
	});

	const onSubmit = () => {
		if (selectedDraft) {
			submit(selectedDraft);
		}
	};

	const onChange = (
		id: React.ComponentProps<typeof Dropdown>['onChange']['arguments'],
	) => {
		if (typeof id !== 'string') {
			throw new Error(
				`[InventoryAllocationDrafts::onChange] 'id' is expected to be of type 'string' but it was not. id: ${id}`,
			);
		}
		const draft = (reports || []).find((report) => report.id === id);
		setSelectedDraft(draft);
	};

	return (
		<>
			<div className="flex justify-between">
				<DraftTabs
					tabs={tabs}
					addDraft={addDraft}
					currentTab={currentTab}
					isAddingDraft={isAddingDraft}
				/>
				<div>
					<Dropdown
						options={reports.filter(isProposal).map(toOption)}
						value={selectedDraft?.id}
						onChange={onChange}
						placeholder={
							reports.filter(isProposal).length === 0
								? 'Finish a draft to submit'
								: 'Select a draft to submit'
						}
						className="whitespace-nowrap overflow-ellipsis
			"
					/>
					<Button onClick={onSubmit} className="ml-4">
						Submit
					</Button>
				</div>
			</div>
			<section className="bg-ghost-white bg-opacity-50 w-[calc(100%+5rem)]min-h-screen -mx-10 px-10 pt-4">
				<Outlet context={{ addDraft, isAddingDraft }} />
			</section>
		</>
	);
};

export default InventoryAllocationDrafts;
