import { Button, Page, PlusIcon, Title } from 'crunch-components';
import { FunctionComponent } from 'react';
import { useInventoryAllocations } from '../../../domains/domains';
import InventoryAllocationsTable from '../components/InventoryAllocationsTable';
// import {
// 	GetLastForecastingSyncParametersDTO,
// 	GetLastForecastingSyncResponseDTO,
// 	GetLastStockSyncParametersDTO,
// 	GetLastStockSyncResponseDTO,
// } from '../../../shared/models/schema';
// import {
// 	GET_LAST_FORECASTING_SYNC,
// 	GET_LAST_STOCK_SYNC,
// } from '../../../shared/api/engine';

const InventoryAllocations: FunctionComponent = () => {
	const { create } = useInventoryAllocations();
	// const { data: lastForecastingSync } = useQuery<
	// 	GetLastForecastingSyncParametersDTO,
	// 	{},
	// 	GetLastForecastingSyncResponseDTO
	// >('last-forecasting-sync-date', GET_LAST_FORECASTING_SYNC, {
	// 	staleTime: 1 * 60 * 1000,
	// 	retry: false,
	// });

	// const { data: lastStockSync } = useQuery<
	// 	GetLastStockSyncParametersDTO,
	// 	{},
	// 	GetLastStockSyncResponseDTO
	// >('last-stock-sync-date', GET_LAST_STOCK_SYNC, {
	// 	staleTime: 1 * 60 * 1000,
	// 	retry: false,
	// });

	return (
		<Page>
			<div className="relative flex justify-between">
				<Title>Inventory Allocations</Title>
			</div>
			<div className="mt-6 relative flex justify-end items-center">
				{/* <div> */}
				{/*	{lastForecastingSync && ( */}
				{/*		<p className="mr-4 text-sm text-gray-500"> */}
				{/*			Last forecasting sync:{' '} */}
				{/*			<strong className="text-black"> */}
				{/*				{dayjs(`${lastForecastingSync.replace(' ', 'T')}.000Z`).format( */}
				{/*					'DD/MM/YYYY HH:mm' */}
				{/*				)} */}
				{/*			</strong> */}
				{/*		</p> */}
				{/*	)} */}
				{/*	{lastStockSync && ( */}
				{/*		<p className="mr-4 text-sm text-gray-500"> */}
				{/*			Last stock levels sync:{' '} */}
				{/*			<strong className="text-black"> */}
				{/*				{dayjs(`${lastStockSync.replace(' ', 'T')}.000Z`).format( */}
				{/*					'DD/MM/YYYY HH:mm' */}
				{/*				)} */}
				{/*			</strong> */}
				{/*		</p> */}
				{/*	)} */}
				{/* </div> */}
				<Button className="flex gap-3" onClick={() => create()}>
					<PlusIcon className="h-3" /> Create new inventory allocation
				</Button>
			</div>
			<InventoryAllocationsTable />
		</Page>
	);
};

export default InventoryAllocations;
