import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import cn from '../../utils/cn';

const LinearProgress = ({ visible, onAnimationEnded }) => {
	const [loaderRef, setLoaderRef] = useState();
	const [isVisible, setIsVisible] = useState(visible);

	if (!isVisible && visible) {
		setIsVisible(true);
	}
	
	useEffect(() => {
		if (loaderRef) {
			const onAnimationIteration = () => {
				if (onAnimationEnded) {
					onAnimationEnded();
				}
				
				if (visible === false) {
					setIsVisible(false);
				}
			};
		
			loaderRef.addEventListener('animationiteration', onAnimationIteration);

			return () => {
				loaderRef.removeEventListener(
					'animationiteration',
					onAnimationIteration
				);
			};	
		}
	}, [visible, loaderRef, onAnimationEnded]);

	if (!isVisible) return null;

	return (
		<div className="relative h-1 overflow-hidden pointer-events-none">
			<div
				className={cn("absolute top-0 left-0 w-full animate-linear-progress transition-all", visible ? 'bg-ca-purple h-full' : 'bg-green-500 h-0')}
				ref={setLoaderRef}
			/>
		</div>
	);
};

LinearProgress.propTypes = {
	visible: PropTypes.bool.isRequired,
	onAnimationEnded: PropTypes.func,
};

LinearProgress.defaultProps = {
	onAnimationEnded: null,
};

export default LinearProgress;
