import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';

import { Auth0Provider, AuthorizationParams } from '@auth0/auth0-react';
import { JWTProvider } from 'crunch-components';
import App from './App';
import './index.css';
import worker from './mocks/worker';

// Set `onUnhandledRequest` to `warn` to log unhandled requests to the console.
if (process.env.REACT_APP_ENVIRONMENT === 'local') {
	worker.start({
		onUnhandledRequest: 'bypass',
	});
}

const authorizationParams: AuthorizationParams = {
	redirect_uri: `${window.location.origin}/login`,
	organization: window._ENV_.REACT_APP_AUTH0_ORGANIZATION_ID,
	audience: `https://${window._ENV_.REACT_APP_AUTH0_DOMAIN}/api/v2/`,
	scope: 'openid profile email offline_access read:current_user',
};

const $root = document.getElementById('root');
const root = createRoot($root!);
root.render(
	<StrictMode>
		<Auth0Provider
			domain={window._ENV_.REACT_APP_AUTH0_DOMAIN}
			clientId={window._ENV_.REACT_APP_AUTH0_CLIENT_ID}
			/* useRefreshTokens */
			cacheLocation="localstorage"
			authorizationParams={authorizationParams}
		>
			<JWTProvider
				auth0Audience={authorizationParams.audience}
				auth0Scope={authorizationParams.scope}
			>
				<App />
			</JWTProvider>
		</Auth0Provider>
	</StrictMode>,
);
