import { useContext } from 'react';

import { ModalContext } from '../contexts/ModalContext';

const useModal = () => {
	const context = useContext(ModalContext);

	if (context === undefined) {
		throw new Error('useModal must be used within a <ModalProvider />');
	}

	return context;
};

export default useModal;

// todo - move modal stuff to crunch components
// not e tath this is aobut context, useModal hook, <Modal> and more? - how should these be placed in directory
