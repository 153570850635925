import cn from "../../utils/cn";

type LoadingSpinnerProps = {
	/** The size of the loading spinner */
	variant?: 'sm' | 'md' | 'lg';
	hidden?: boolean;
	/** Wheter or not to take up space even while hidden. Prevents elements from changing place when toggling between hidden/unhidden. */
	antiVisualJumping?: boolean; // prevent html to jump around when loading icon (dis)appears
	/** (optional) Classname for parent element. */
	className?: string;
};

/**
 * A spinning half circle. Indicator for elements which are still waiting to be updated.
 */
const LoadingSpinner: React.FC<LoadingSpinnerProps> = (props) => {
	return props?.hidden && !props.antiVisualJumping ? (
		<></>
	) : (
		<div
			className={cn(
				(props?.variant === 'sm' || props.variant === undefined) &&
					'mx-1 w-2 h-2 border-2',
				props?.variant === 'md' && 'mx-2 w-4 h-4 border-2',
				props?.variant === 'lg' && 'mx-2 w-6 h-6 border-2',
				props?.className ?? '',
				props?.antiVisualJumping && props?.hidden ? 'opacity-0' : 'opacity-100',
				'inline-block rounded-full animate-[spin_0.6s_linear_infinite] border-l-ca-purple border-t-ca-purple border-r-transparent border-b-transparent transition-opacity'
			)}
		/>
	);
};

export default LoadingSpinner;
