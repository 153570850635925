import { MutationDTO } from '../shared/models/API';
import {
	GetEngineStatusResponseDTO,
	GetLastForecastingSyncResponseDTO,
	GetLastStockSyncResponseDTO,
	ReportDTO,
	StartEngineBodyDTO,
	StartEngineParametersDTO,
	StartEngineResponseDTO,
} from '../shared/models/schema';
import api from './api';

export const GET_LAST_STOCK_SYNC = (): Promise<GetLastStockSyncResponseDTO> => {
	return api.get('api/v1/engine/last_data_sync_stock_levels').json();
};

export const GET_LAST_FORECASTING_SYNC =
	(): Promise<GetLastForecastingSyncResponseDTO> => {
		return api.get('api/v1/engine/last_data_sync_forecasting').json();
	};

export const START_ENGINE: MutationDTO<
	StartEngineParametersDTO,
	StartEngineBodyDTO,
	StartEngineResponseDTO
> = ({ allocation_id, report_id }) => {
	return api
		.post('api/v1/engine/', {
			searchParams: new URLSearchParams([
				['allocation_id', allocation_id],
				['report_id', report_id],
			]),
		})
		.json();
};

export const GET_ENGINE_STATUS = (
	reportId: ReportDTO['id']
): Promise<GetEngineStatusResponseDTO> => {
	return api
		.get('api/v1/engine/status', {
			searchParams: new URLSearchParams([['report_id', reportId]]),
		})
		.json();
};
