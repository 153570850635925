/* eslint-disable */

import cn from '../../utils/cn';
import { IconBaseProps } from './IconBase';

type Orientation = 'up' | 'right' | 'down' | 'left';
type CaretProps = IconBaseProps & {
	orientation: Orientation;
};

const orientationToRotate: Record<Orientation, string> = {
	up: 'rotate-180',
	right: '-rotate-90',
	down: 'rotate-0',
	left: 'rotate-90',
} as const;

const Caret = (props: CaretProps) => (
	<svg
		className={cn(props.className, orientationToRotate[props.orientation])}
		xmlns="http://www.w3.org/2000/svg"
		width="32"
		height="32"
		viewBox="0 0 24 24"
	>
		<path
			fill="currentColor"
			d="M8.12 9.29L12 13.17l3.88-3.88a.996.996 0 1 1 1.41 1.41l-4.59 4.59a.996.996 0 0 1-1.41 0L6.7 10.7a.996.996 0 0 1 0-1.41c.39-.38 1.03-.39 1.42 0"
		/>
	</svg>
);

export default Caret;
