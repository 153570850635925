// @ts-nocheck
// ^ Because of package problems

import Tippy from '@tippyjs/react/headless';
import { PropsWithChildren } from 'react';

const Tooltip = ({
	children,
	content,
	isVisible,
	placement = 'top',
}: PropsWithChildren<{
	content?: React.ReactNode;
	placement?:
		| 'top'
		| 'top-start'
		| 'top-end'
		| 'right'
		| 'right-start'
		| 'right-end'
		| 'bottom'
		| 'bottom-start'
		| 'bottom-end'
		| 'left'
		| 'left-start'
		| 'left-end';
	isVisible?: boolean;
}>) => {
	if (!content) return <>{children}</>;

	return (
		<Tippy
			placement={placement}
			visible={isVisible}
			render={(attrs) => (
				<div
					className="bg-white text-ca-black text-xs p-2.5 rounded-lg shadow-ca max-w-sm"
					tabIndex={-1}
					{...attrs}
				>
					{content}
				</div>
			)}
		>
			{children}
		</Tippy>
	);
};

export default Tooltip;
