import { Title } from 'crunch-components';
import { FunctionComponent } from 'react';
import {
	Control,
	FieldArrayWithId,
	UseFieldArrayReturn,
} from 'react-hook-form';
import { Configuration } from '../../../shared/models/configuration';
import { InventoryAllocation } from '../../../shared/models/inventoryAllocation';
import { InventoryAllocationReport } from '../../../shared/models/inventoryAllocationReport';
import SecondaryObjectiveFieldset from './SecondaryObjectiveFieldset';
import { NoSecondaryObjectives } from './SecondaryObjectiveOption';
import SecondaryObjectivePicker from './SecondaryObjectivePicker';

interface Props {
	inventoryAllocationId: InventoryAllocation['id'];
	reportId: InventoryAllocationReport['id'];
	fields: FieldArrayWithId<Configuration, 'secondaryObjectives', 'id'>[];
	append: UseFieldArrayReturn<Configuration, 'secondaryObjectives'>['append'];
	remove: UseFieldArrayReturn['remove'];
	control: Control<Configuration>;
}

const SecondaryObjectivesFieldset: FunctionComponent<Props> = ({
	inventoryAllocationId,
	reportId,
	append,
	control,
	fields,
	remove,
}) => {
	return (
		<div className="my-16">
			<Title type="section">Secondary objectives</Title>
			<p className="text-ca-gray max-w-lg pt-2 pb-8">
				Secondary objectives will be pursued as much as needed, but cannot be
				enforced.
			</p>
			<div className="flex flex-col gap-2">
				{fields.length === 0 && <NoSecondaryObjectives />}
				{fields.map((field, index) => (
					<SecondaryObjectiveFieldset
						inventoryAllocationId={inventoryAllocationId}
						reportId={reportId}
						key={field.id}
						secondaryObjective={field}
						index={index}
						remove={remove}
						control={control}
					/>
				))}
				<SecondaryObjectivePicker
					append={append}
					configuredTypes={fields.map(({ type }) => type)}
				/>
			</div>
		</div>
	);
};

export default SecondaryObjectivesFieldset;
