import { GET_INVENTORY_ALLOCATION } from 'api/inventory-allocations';
import { LoadingSpinner, Skeleton, Text } from 'crunch-components';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { useInventoryAllocations } from '../../../domains/domains';
import { Configuration } from '../../../shared/models/configuration';
import InventoryAllocationDrafts from './InventoryAllocationDrafts';
import InventoryAllocationReportDetail from './InventoryAllocationReportDetail';

const InventoryAllocationDetail = () => {
	const { inventoryAllocationId } = useParams();
	if (inventoryAllocationId === undefined) {
		throw new Error(
			`[InventoryAllocationDetail] inventoryAllocationId is undefined. Developer error.`,
		);
	}

	const {
		create,
		addDraft,
		submit,
		isAddingDraft,
		entity: inventoryAllocation,
		isLoading,
	} = useInventoryAllocations(
		useQuery(['inventory-allocations', inventoryAllocationId], () =>
			GET_INVENTORY_ALLOCATION(inventoryAllocationId),
		),
	);

	if (inventoryAllocation === undefined) {
		return (
			<Skeleton className="flex w-full h-96 rounded-lg mt-4 gap-3 justify-center items-center">
				<LoadingSpinner className="h-8 w-8" />
				<Text type="secondary">Loading...</Text>
			</Skeleton>
		);
	}

	const { id, drafts, report } = inventoryAllocation!;

	if (report !== undefined) {
		throw new Error(
			'I am convinced that this is never rendered. If it is, yell at Ward.',
		);

		const copyConfigurationToNewInventoryAllocation = (
			configuration: Configuration,
		): void => {
			create(configuration);
		};
		return (
			<InventoryAllocationReportDetail
				inventoryAllocationId={id}
				draftId={report!}
				isCopyingConfiguration={isLoading}
				isAddingDraft={isLoading}
				onCopyConfiguration={copyConfigurationToNewInventoryAllocation}
				onAddDraft={() => addDraft(id, drafts)}
				warningMessage={undefined}
			/>
		);
	}

	return (
		<InventoryAllocationDrafts
			inventoryAllocationId={id}
			drafts={drafts}
			isAddingDraft={isAddingDraft}
			addDraft={(configuration) => addDraft(id, drafts, configuration)}
			submit={(draft) => submit(id, draft.id, draft.title)}
		/>
	);
};

export default InventoryAllocationDetail;
