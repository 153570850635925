import PropTypes from 'prop-types';

const Pill = ({ children }) => {
	return (
		<span className="rounded-full py-2 px-4 text-white bg-ca-gray">
			{children}
		</span>
	);
};

Pill.propTypes = {
	children: PropTypes.node.isRequired,
};

export default Pill;
