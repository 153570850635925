import cn from '../../utils/cn';
import { PropsWithChildren } from 'react';

export type TextType = 'primary' | 'secondary' | 'inverted';
export type TextSize =
	| 'text-xxs'
	| 'text-xs'
	| 'text-sm'
	| 'text-base'
	| 'text-lg'
	| 'text-xl'
	| 'text-2xl'
	| 'text-3xl'
	| 'text-4xl'
	| 'text-5xl'
	| 'text-6xl'
	| 'text-ca-screen'
	| 'text-ca-section';

const Text = ({
	children,
	type = 'primary',
	size = 'text-sm',
	className = '',
	style = {},
}: PropsWithChildren<{
	type?: TextType;
	size?: TextSize;
	className?: string;
	style?: {};
}>) => (
	<p
		style={style}
		className={cn(
			size,
			type === 'primary' && 'text-ca-black',
			type === 'secondary' && 'text-ca-gray',
			type === 'inverted' && 'text-white',
			className
		)}
	>
		{children}
	</p>
);

export default Text;
