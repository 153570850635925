import { useQuery } from 'react-query';
import { useOutletContext, useParams } from 'react-router-dom';
import { GET_INVENTORY_ALLOCATION_REPORT } from '../../../api/inventory-allocation-reports';
import { useReports } from '../../../domains/domains';
import { Configuration } from '../../../shared/models/configuration';
import { InventoryAllocationReportStatus } from '../../../shared/models/inventoryAllocationReport';
import { ScopeTypeDTO } from '../../../shared/models/schema';
import ConfigurationForm from './configurationForm';
import { InventoryAllocationOutletContext } from './InventoryAllocationDrafts';
import InventoryAllocationReportDetail from './InventoryAllocationReportDetail';
import InventoryAllocationReportInProgress from './InventoryAllocationReportInProgress';
import InventoryAllocationReportNoMovements from './InventoryAllocationReportNoMovements';

const DraftReport = () => {
	const { inventoryAllocationId, draftId } = useParams();
	if (inventoryAllocationId === undefined || draftId === undefined) {
		throw new Error(
			`[DraftReport] url param for 'inventoryAllocationId' or 'draftId' was/were undefined. inventoryAllocationId:'${inventoryAllocationId}', draftId:'${draftId}'.`,
		);
	}
	const { addDraft, isAddingDraft } =
		useOutletContext<InventoryAllocationOutletContext>();

	// TODO change reports to drafts? don't know anymore
	const result = useQuery(['reports', draftId], () =>
		GET_INVENTORY_ALLOCATION_REPORT(inventoryAllocationId, draftId),
	);
	const {
		isLoading,
		entity: draft,
		update,
		startAlgorithm,
	} = useReports(result);

	const saveConfiguration = (configuration: Configuration): void => {
		update({
			id: draftId,
			configuration,
			inventoryAllocationId: inventoryAllocationId,
			title: draft?.title || '',
		});
	};

	const copyConfigurationToNewDraft = (configuration: Configuration): void => {
		addDraft(configuration);
	};

	if (
		isLoading ||
		draft?.status === InventoryAllocationReportStatus.InProgress
	) {
		<InventoryAllocationReportInProgress
			addDraft={addDraft}
			isAddingDraft={isAddingDraft}
		/>;
	}

	if (draft?.status === InventoryAllocationReportStatus.NoSuggestedMoves) {
		<InventoryAllocationReportNoMovements
			addDraft={addDraft}
			isAddingDraft={isAddingDraft}
		/>;
	}

	if (
		draft?.status === InventoryAllocationReportStatus.Draft ||
		draft?.status === InventoryAllocationReportStatus.Error
	) {
		return (
			<ConfigurationForm
				isLoading={isLoading}
				onChange={saveConfiguration}
				defaultValues={draft.configuration}
				inventoryAllocationId={inventoryAllocationId}
				defaultFilters={
					(
						result.data?.scopes?.find(
							({ scope_type }) => scope_type === ScopeTypeDTO.ProductScope,
						) as any
					)?.filters
				}
				reportId={draftId}
				validationMessage={
					draft?.status === InventoryAllocationReportStatus.Error
						? draft.message ||
							'An unknown error ocurred while calculating the optimal inventory allocation.'
						: undefined
				}
				onSubmit={() => startAlgorithm(inventoryAllocationId, draft.id)}
			/>
		);
	}
	return (
		<InventoryAllocationReportDetail
			inventoryAllocationId={inventoryAllocationId}
			draftId={draftId}
			onCopyConfiguration={copyConfigurationToNewDraft}
			onAddDraft={addDraft}
			isCopyingConfiguration={isAddingDraft}
			isAddingDraft={isAddingDraft}
			warningMessage={
				draft?.status === InventoryAllocationReportStatus.Outdated
					? 'Report is not up to date, please rerun the report.'
					: undefined
			}
		/>
	);
};

export default DraftReport;
