import { FunctionComponent, useState } from 'react';

import { Button, Modal, Tooltip, useModal } from 'crunch-components';
import { InventoryAllocation } from 'shared/models/inventoryAllocation';
import { InventoryAllocationReport } from 'shared/models/inventoryAllocationReport';
import { ProductDTO } from 'shared/models/schema';
import ProductsTable from '../components/ProductsTable';

interface Props {
	inventoryAllocationId: InventoryAllocation['id'];
	reportId: InventoryAllocationReport['id'];
	selectedProducts: ProductDTO['product_id'][];
	defaultFilters?: any[];
	setSelectedProducts?: (products: ProductDTO['product_id'][]) => void;
}

const ProductsTableModal: FunctionComponent<Props> = ({
	selectedProducts,
	setSelectedProducts,
	inventoryAllocationId,
	defaultFilters,
	reportId,
}) => {
	const { close: rawClose } = useModal();
	const close = () => {
		if (rawClose !== undefined) {
			rawClose();
		}
	};
	const [shouldAskForConfirmation, setShouldAskForConfirmation] =
		useState<boolean>(false);
	const [askForConfirmation, setAskForConfirmation] = useState<boolean>(false);

	const onDone = () => {
		if (shouldAskForConfirmation) {
			setAskForConfirmation(true);
		} else {
			close();
		}
	};

	const onIAmSure = () => {
		setAskForConfirmation(false);
		close();
	};

	return (
		<div className="w-full">
			<div className="w-full bg-white rounded-2xl shadow-ca max-h-95vh overflow-y-scroll">
				<Modal.Content>
					<Modal.Title>Products in scope</Modal.Title>
					<ProductsTable
						defaultFilters={defaultFilters}
						selectedProducts={selectedProducts}
						inventoryAllocationId={inventoryAllocationId}
						reportId={reportId}
						setSelectedProducts={setSelectedProducts}
						setShouldAskForConfirmation={setShouldAskForConfirmation}
						isReadOnly={!setSelectedProducts}
					/>
				</Modal.Content>
				<Modal.Actions className="flex justify-end">
					{!askForConfirmation && <Button onClick={onDone}>Done</Button>}
					{askForConfirmation && (
						<div className="flex gap-4">
							<Button
								onClick={() => setAskForConfirmation(false)}
								variant="secondary"
							>
								No, go back!
							</Button>
							<Tooltip
								isVisible
								content="Are you sure? You have not selected any products yet!"
							>
								<Button onClick={onIAmSure}>Yes, I am sure!</Button>
							</Tooltip>
						</div>
					)}
				</Modal.Actions>
			</div>
		</div>
	);
};

export default ProductsTableModal;
