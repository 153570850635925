import { QueryKey, useMutation, useQueryClient } from 'react-query';
import { MutationDTO } from '../shared/models/API';

const useUpdateMutation = <Params, RequestBody, Response>(
	queryKey: QueryKey,
	mutation: MutationDTO<Params, RequestBody, Response>,
	invalidateQueries?: QueryKey
) => {
	const queryClient = useQueryClient();

	const result = useMutation(mutation, {
		onMutate: async (updatedEntity: any) => {
			await queryClient.cancelQueries(queryKey);
			const previousEntities = queryClient.getQueryData(queryKey);

			queryClient.setQueryData<Response>(queryKey, (old: any): any => {
				const items: any[] = (old?.items || []).map((entity: any) => {
					if (entity.id === updatedEntity.id) {
						return { ...(entity as any), ...(updatedEntity as any) };
					}

					return entity;
				});

				return {
					items,
					total: old?.total ?? items.length,
				};
			});

			return { previousEntities };
		},
		onError: (err, updatedEntity, context) => {
			queryClient.setQueryData(queryKey, context?.previousEntities);
		},
		onSettled: () => {
			queryClient.invalidateQueries(queryKey);
			if (invalidateQueries) {
				queryClient.invalidateQueries(invalidateQueries);
			}
		},
	});

	return result;
};

export default useUpdateMutation;
