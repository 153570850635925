import { Transition } from '@headlessui/react';
import { search } from 'crunch-utils';
import PropTypes from 'prop-types';
import { Fragment, useEffect, useState } from 'react';
import Button from '../Button/Button';
import Text from '../Text/Text';
import Slider from '../Slider/Slider';
import Checkbox from '../Checkbox/Checkbox';
import Dropdown from '../Dropdown/Dropdown';
import cn from '../../utils/cn';

const FilterValueMenu = ({
	field,
	id,
	value,
	options,
	type,
	decimals,
	inputProps,
	percentage,
	visible,
	className,
	isDefault,
	onApply,
	onCancel,
	onRemove,
}) => {
	const [localValue, setLocalValue] = useState(value);
	const [selectAll, setSelectAll] = useState(false);
	const step = options?.[1] - options?.[0] <= 1 ? 5 : 1;
	// Function to update the "Select All" state based on the current selection
	const updateSelectAllState = (selectedOptions) => {
		setSelectAll(
			options.length &&
			options.every((option) => selectedOptions.includes(option))
		);
	};

	useEffect(() => {
		// Initialize localValue and selectAll state when the component mounts or when value/type changes
		if (type === 'Checkbox') {
			setLocalValue(value);
			updateSelectAllState(value);
		} else if (type === 'Range' && !Array.isArray(value)) {
			const [min, max] = value ? value.split(' - ') : [options[0], options[1]];
			setLocalValue([
				parseFloat(min).toFixed(decimals),
				parseFloat(max).toFixed(decimals),
			]);
		}
	}, [value, type, options, decimals]);

	useEffect(() => {
		// Update "Select All" state right before the menu becomes visible
		if (visible && type === 'Checkbox') {
			updateSelectAllState(localValue);
		}
	}, [visible, localValue, options, type]);

	const handleChecked = (option, checked) => {
		let updatedLocalValue;
		if (checked) {
			updatedLocalValue = [...localValue, option];
		} else {
			updatedLocalValue = localValue.filter((v) => v !== option);
		}
		setLocalValue(updatedLocalValue);

		// Update "Select All" state based on the updated selection
		updateSelectAllState(updatedLocalValue);
	};

	const handleSelectAllChange = (checked) => {
		setSelectAll(checked);
		if (checked) {
			setLocalValue(options);
		} else {
			setLocalValue([]);
		}
	};

	const [query, setQuery] = useState('');
	const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 });

	const calculatePosition = () => {
		const fullMenu = document.querySelector(`#filter-field-menu`);
		const parentMenu = document.querySelector(
			`#filter-field-menu div[name="${id}"]`
		);
		if (parentMenu && fullMenu) {
			const parentRect = parentMenu.getBoundingClientRect();
			const fullMenuRect = fullMenu.getBoundingClientRect();
			setMenuPosition({
				top: parentRect.top - fullMenuRect.top,
			});
		}
	};
	useEffect(() => {
		calculatePosition();
	}, [visible]);

	return (
		<Transition
			appear
			show={visible}
			as={Fragment}
			enter="transition ease-out duration-100"
			enterFrom="transform opacity-0 scale-95"
			enterTo="transform opacity-100 scale-100"
			leave="transition ease-in duration-75"
			leaveFrom="transform opacity-100 scale-100"
			leaveTo="transform opacity-0 scale-95"
		>
			<div
				id="filter-value-menu"
				className={cn(
					'absolute origin-top-left px-5 py-5 bg-white rounded-lg shadow-ca focus:outline-none z-20',
					className
				)}
				style={{ top: `${menuPosition.top}px` }}
			>
				<Text size="text-xs" className="font-bold">
					{field}
				</Text>
				<div
					className={cn(
						'py-4 space-y-2',
						type === 'Checkbox' && 'max-h-40 overflow-y-auto'
					)}
				>
					{type === 'List' && (
						<Dropdown
							onChange={setLocalValue}
							value={localValue}
							options={options.map((option) => ({
								label: option,
								value: option,
							}))}
							className="w-full"
							{...inputProps}
						/>
					)}

					{type === 'Checkbox' && (
						<>
							<Checkbox
								checked={selectAll}
								label="Select All"
								onChange={(checked) => handleSelectAllChange(checked)}
							/>
							<input
								className="text-sm text-black"
								placeholder="Search"
								onChange={(event) => setQuery(event.target.value)}
							/>
							{options
								.filter((option) => {
									if (query === '') {
										return option;
									}
									if (search(option, query)) {
										return option;
									}
									return null;
								})
								.map((option) => (
									<Checkbox
										key={option}
										checked={localValue?.includes(option)}
										label={option}
										onChange={(checked) => handleChecked(option, checked)}
									/>
								))}
						</>
					)}
					{type === 'Range' && Array.isArray(localValue) && (
						<Slider
							value={(localValue || []).map((lv) =>
								(lv * (percentage ? 100 : 1)).toFixed(decimals)
							)}
							className="mb-2 w-64"
							onChange={(val) =>
								setLocalValue(
									val.map((lv) => lv.toFixed(decimals) / (percentage ? 100 : 1))
								)
							}
							min={percentage ? options?.[0] * 100 : options?.[0]}
							max={percentage ? options?.[1] * 100 : options?.[1]}
							step={step}
							textInputProps={percentage ? { endAdornment: '%' } : {}}
						/>
					)}
				</div>
				<div className="flex justify-between">
					{onRemove && (
						<Button size="small" variant="secondary" onClick={onRemove}>
							{isDefault ? 'Reset' : 'Remove'}
						</Button>
					)}
					{onCancel && (
						<Button size="small" variant="secondary" onClick={onCancel}>
							Cancel
						</Button>
					)}
					<Button
						disabled={!localValue || !localValue?.length}
						size="small"
						onClick={() => onApply(localValue)}
					>
						Select
					</Button>
				</div>
			</div>
		</Transition>
	);
};

FilterValueMenu.propTypes = {
	field: PropTypes.string.isRequired,
	id: PropTypes.string,
	value: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.string),
		PropTypes.arrayOf(PropTypes.number),
		PropTypes.string,
		PropTypes.number,
	]),
	options: PropTypes.arrayOf(PropTypes.any),
	type: PropTypes.oneOf([null, 'List', 'Checkbox', 'Range']),
	inputProps: PropTypes.any,
	percentage: PropTypes.bool,
	decimals: PropTypes.number,
	visible: PropTypes.bool,
	className: PropTypes.string,
	isDefault: PropTypes.bool,
	onApply: PropTypes.func,
	onCancel: PropTypes.func,
	onRemove: PropTypes.func,
};

FilterValueMenu.defaultProps = {
	value: '',
	id: '',
	options: [],
	type: null,
	percentage: false,
	decimals: 0,
	visible: false,
	inputProps: {},
	className: '',
	isDefault: false,
	onApply: () => { },
	onCancel: null,
	onRemove: null,
};

export default FilterValueMenu;
