import { useJWTStore } from 'crunch-components';
import ky from 'ky';

const api = ky.extend({
	prefixUrl: window._ENV_.REACT_APP_VULCAN_API,
	timeout: window._ENV_.REACT_APP_API_TIMEOUT || 30000,
	hooks: {
		beforeRequest: [
			(request) => {
				const token = useJWTStore.getState()?.jwt?.access_token;

				if (token) {
					request.headers.set('Authorization', `Bearer ${token}`);
				}
			},
		],
		afterResponse: [
			(_request, _options, response) => {
				// if no jwt is found, log the user out
				if (response.status === 401) {
					useJWTStore.setState({ jwt: null });
				}
			},
		],
	},
});

export default api;
