import PropTypes from 'prop-types';

const Settings = ({ className }) => (
	<svg
		className={className}
		viewBox="0 0 120 120"
		xmlns="http://www.w3.org/2000/svg"
	>
		<circle cx="12" cy="12" r="12" fill="currentColor" />
		<circle cx="60" cy="12" r="12" fill="currentColor" />
		<circle cx="108" cy="12" r="12" fill="currentColor" />
		<circle cx="12" cy="60" r="12" fill="currentColor" />
		<circle cx="60" cy="60" r="12" fill="currentColor" />
		<circle cx="108" cy="60" r="12" fill="currentColor" />
		<circle cx="12" cy="108" r="12" fill="currentColor" />
		<circle cx="60" cy="108" r="12" fill="currentColor" />
		<circle cx="108" cy="108" r="12" fill="currentColor" />
	</svg>
);

Settings.propTypes = {
	className: PropTypes.string,
};

Settings.defaultProps = {
	className: '',
};

export default Settings;
