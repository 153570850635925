import { Button, Modal, Text, useModal } from 'crunch-components';
import { FunctionComponent } from 'react';

interface Props {
	title: string;
	onAccept: () => void;
}

const ConfirmModal: FunctionComponent<Props> = ({ title, onAccept }) => {
	const { close: rawClose } = useModal();
	const close = () => {
		if (rawClose !== undefined) {
			rawClose();
		}
	};

	const onAcceptClick = () => {
		onAccept();
		close();
	};

	return (
		<Modal.Root>
			<Modal.Content>
				<Modal.Title>{title}</Modal.Title>
				<div className="space-y-4">
					<Text>Are you sure you want to delete this?</Text>
				</div>
				<Text className="italic">
					This action is irreversible. Please proceed with caution.
				</Text>
			</Modal.Content>
			<Modal.Actions>
				<div className="flex justify-between">
					<Button variant="secondary" onClick={close}>
						Cancel
					</Button>
					<Button variant="danger" onClick={onAcceptClick}>
						Delete
					</Button>
				</div>
			</Modal.Actions>
		</Modal.Root>
	);
};

export default ConfirmModal;
