import { ExperimentIcon } from 'crunch-components';
import { NavLink, useNavigate } from 'react-router-dom';

import {
	cn,
	HelpIcon,
	Logo,
	LogOutIcon,
	Tooltip,
	useJWTStore,
} from 'crunch-components';

import { useAuth0 } from '@auth0/auth0-react';
import { ElementType } from 'react';
import { DEFAULT_PATHS } from 'utils/routerUtils';

const VULCAN_SIDEBAR_ITEMS = [
	{
		id: 'inventory-allocations',
		label: 'Inventory Allocations',
		to: '/stock/inventory-allocations',
		icon: ExperimentIcon,
	},
] as const;

type SidebarNavLinkProps = {
	icon: ElementType;
	label: string;
	to: string;
};
function SidebarNavLink(props: SidebarNavLinkProps) {
	const Icon = props.icon;

	return (
		<NavLink
			to={props.to}
			className={(isActive) =>
				cn('group/li', isActive ? 'active' : 'not-active')
			}
		>
			<li className="relative flex flex-col flex-wrap items-center py-5 text-ca-gray cursor-pointer hover:border-ca-purple hover:text-ca-purple transition-all before:transition-all before:absolute before:left-0 before:top-0 before:h-full before:bg-ca-purple group-[.active]/li:before:w-1 group-[.not-active]/li:before:w-0">
				<Icon
					className={cn(
						'w-5 transition-colors',
						'group-[.active]/li:text-ca-purple',
					)}
				/>
				<span className="mt-4 max-w-4/5 text-center text-xs uppercase font-semibold tracking-tighter transition-all group-hover:h-4 group-[.active]/li:text-ca-purple group-hover/ul:h-4 group-[.active]/li:h-4 group-[.active]/li:opacity-100 h-0 opacity-0 group-hover/ul:opacity-100">
					{props.label}
				</span>
			</li>
		</NavLink>
	);
}

function LogoNav() {
	const navigate = useNavigate();

	return (
		<div
			role="button"
			tabIndex={0}
			className="relative cursor-pointer"
			onClick={() => navigate(DEFAULT_PATHS['private'])}
			onKeyDown={() => navigate(DEFAULT_PATHS['private'])}
		>
			<Logo className="w-12 h-full" />
		</div>
	);
}

export default function VulcanSidebar() {
	const { logout: auth0Logout } = useAuth0();
	const { setJWT } = useJWTStore();
	const navigate = useNavigate();

	const logout = async () => {
		setJWT(null);
		await auth0Logout({
			logoutParams: {
				returnTo: window.location.origin, //todo ward: what does this do?this is now localhost:3000
			},
		});
		navigate('/login', { state: { from: window.location.pathname } });
	};

	const openDocPage = () => {
		window.open('http://knowledge.crunchplatform.eu');
	};

	return (
		<div className="w-40 shrink-0 h-screen flex flex-col justify-between bg-white border-r border-ca-black border-opacity-5 z-10 sticky top-0">
			<div>
				<div className="flex justify-center py-10">
					<LogoNav />
				</div>
				<ul className="flex flex-col py-10 group/ul">
					{VULCAN_SIDEBAR_ITEMS.map((item) => {
						return (
							<SidebarNavLink
								key={item.label}
								icon={item.icon}
								label={item.label}
								to={item.to}
							/>
						);
					})}
				</ul>
			</div>
			<div className="flex justify-center items-center p-10 flex-col">
				<Tooltip content="Link to Knowledge Base">
					<button
						className="p-4 border-2 border-ca-silver rounded-full hover:border-ca-purple focus:border-ca-purple group transition-colors mb-2"
						type="button"
						onClick={openDocPage}
					>
						<HelpIcon className="w-4 text-ca-gray group-hover:text-ca-purple transition-colors" />
					</button>
				</Tooltip>
				<button
					className="p-4 border-2 border-ca-silver rounded-full hover:border-ca-purple focus:border-ca-purple group transition-colors"
					type="button"
					onClick={logout}
				>
					<LogOutIcon className="w-4 text-ca-gray group-hover:text-ca-purple transition-colors" />
				</button>
			</div>
		</div>
	);
}
