import IconBase from './IconBase';

const Plus: IconBase = ({ className = '' }) => (
	<svg
		className={className}
		viewBox="0 0 12 12"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="m7 5h5v2h-5v5h-2v-5h-5v-2h5v-5h2z"
			fill="currentColor"
			fillRule="evenodd"
		/>
	</svg>
);

export default Plus;
