import {
	GetProductIdsResponseDTO,
	GetProductsFiltersResponseDTO,
	GetProductsResponseDTO,
	GetProductsSchemaResponseDTO,
} from '../shared/models/schema';
import api from './api';

export const GET_INVENTORY_ALLOCATION_PRODUCTS = (
	searchParams: URLSearchParams
): Promise<GetProductsResponseDTO> => {
	return api.get('api/v1/products', { searchParams }).json();
};

export const GET_INVENTORY_ALLOCATION_PRODUCT_SCHEMA =
	(): Promise<GetProductsSchemaResponseDTO> => {
		return api.get('api/v1/products/schema').json();
	};

export const GET_INVENTORY_ALLOCATION_PRODUCT_FILTERS =
	(): Promise<GetProductsFiltersResponseDTO> => {
		return api.get('api/v1/products/filters').json();
	};

export const GET_INVENTORY_ALLOCATION_PRODUCT_IDS = (
	searchParams: URLSearchParams
): Promise<GetProductIdsResponseDTO> => {
	return api.get('api/v1/products/ids', { searchParams }).json();
};
