import { Transition } from '@headlessui/react';
import cn from '../utils/cn';
import { createContext, Fragment, useCallback, useState } from 'react';
import useKeyPress from '../hooks/useKeyPress';
import clsx from 'clsx';

type ModalOpenParams = {
	modalNode: React.ReactNode;
	sizeClass?: string;
	mode?: 'priority';
	id?: string;
	onClose?: () => void;
};

type ModalContextValue = {
	open: (params: ModalOpenParams) => void;
	close?: (id?: string) => void;
};

const ModalContext = createContext<ModalContextValue>({
	open: () => {
		throw new Error('[ModalContext::open] ModalContext not initialized');
	},
	close: () => {
		throw new Error('[ModalContext::close] ModalContext not initialized');
	},
});

const ModalProvider = ({ children }: { children: React.ReactNode }) => {
	const [modal, setModal] = useState<ModalOpenParams['modalNode']>();
	const [sizeClass, setSizeClass] = useState<ModalOpenParams['sizeClass']>();
	const [operationMode, setOperationMode] = useState<ModalOpenParams['mode']>();
	const [identifier, setIdentifier] =
		useState<ModalOpenParams['id']>(undefined);
	const [onCloseFn, setOnCloseFn] =
		useState<ModalOpenParams['onClose']>(undefined);

	const open: ModalContextValue['open'] = ({
		modalNode,
		id,
		mode,
		onClose,
		sizeClass: sC,
	}) => {
		setModal(modalNode);
		setSizeClass(sC);
		setOperationMode(mode);
		setIdentifier(id);
		setOnCloseFn(() => {
			return onClose;
		});

		document.body.style.overflow = 'hidden';
	};

	const close = useCallback(
		(id: string | undefined = undefined) => {
			if (id !== undefined && identifier !== id) {
				return;
			}

			console.log('should delete all params');
			if (onCloseFn) {
				onCloseFn();
			}

			setModal(null);
			setSizeClass(undefined);
			setOperationMode(undefined);
			setOnCloseFn(undefined);
			setIdentifier(undefined);
			document.body.style.overflow = '';
		},
		[onCloseFn, modal, identifier],
	);

	const nonPriorityClose = () => {
		if (operationMode !== 'priority') {
			close(identifier);
		}
	};

	useKeyPress('Escape', nonPriorityClose);

	// TODO WARD: check if modal context still works...
	return (
		<ModalContext.Provider value={{ open, close }}>
			{children}
			<Transition appear show={!!modal} as={Fragment}>
				<div className="z-50 fixed left-0 right-0 bottom-0 top-0">
					<div
						role="presentation"
						className="absolute left-0 right-0 bottom-0 top-0 backdrop-filter backdrop-blur-sm"
						style={{ background: 'rgba(230, 230, 230, 0.5)' }}
						onClick={nonPriorityClose}
					/>
					<div
						className={clsx(
							'absolute left-1/2 top-1/2 transform -translate-y-1/2 -translate-x-1/2',
							sizeClass,
						)}
					>
						<Transition.Child
							enter="transition ease-out duration-100"
							enterFrom="transform opacity-0 scale-95"
							enterTo="transform opacity-100 scale-100"
							leave="transition ease-in duration-75"
							leaveFrom="transform opacity-100 scale-100"
							leaveTo="transform opacity-0 scale-95"
						>
							<div>{modal}</div>
						</Transition.Child>
					</div>
				</div>
			</Transition>
		</ModalContext.Provider>
	);
};

export { ModalContext, ModalProvider };
