import { Transition } from '@headlessui/react';
import clsx from 'clsx';
import { KebabIcon, useKeyPress, useOnClickOutside } from 'crunch-components';
import {
	FunctionComponent,
	MouseEvent,
	ReactNode,
	useEffect,
	useRef,
	useState,
} from 'react';

interface Props {
	isVisible?: boolean;
	children: ReactNode;
}

const ActionsMenu: FunctionComponent<Props> = ({ isVisible, children }) => {
	const ref = useRef<HTMLParagraphElement>(null);
	const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
	useOnClickOutside(ref, () => setIsDropdownOpen(false));
	useKeyPress('Escape', () => setIsDropdownOpen(false));

	function onClick(event: MouseEvent<HTMLButtonElement>): void {
		event.stopPropagation();
		setIsDropdownOpen(!isDropdownOpen);
	}

	useEffect(() => {
		setIsDropdownOpen(false);
	}, [isVisible]);

	return (
		<p
			ref={ref}
			className={clsx(isVisible ? 'opacity-100' : 'opacity-0', 'relative')}
		>
			<button onClick={onClick} className="px-2">
				<KebabIcon />
			</button>
			<Transition show={isDropdownOpen}>
				<div className="origin-top-left absolute -top-4 right-20 overflow-visible w-48 py-1 bg-white rounded-lg shadow-ca cursor-pointer focus:outline-none z-20">
					{children}
				</div>
			</Transition>
		</p>
	);
};

export default ActionsMenu;
