import cn from '../../utils/cn';
import PropTypes from 'prop-types';
import { useState } from 'react';
import ReactSlider from 'react-slider';

import TextInput from '../TextInput/TextInput';

export const SingleTrack = (props, state) => (
	<div
		{...props}
		className={cn(
			'top-1.5 h-0.5 rounded-full',
			state.index === 0 ? 'bg-ca-purple' : 'bg-ca-silver'
		)}
	/>
);

export const DoubleTrack = (props, state) => (
	<div
		{...props}
		className={cn(
			'top-1.5 h-0.5 rounded-full',
			state.index === 1 ? 'bg-ca-purple' : 'bg-ca-silver'
		)}
	/>
);

export const Thumb = (props) => (
	<div
		{...props}
		className="w-4 h-4 bg-white border-2 border-ca-purple rounded-full cursor-grab focus-visible:outline-none"
	/>
);

const Slider = ({
	value,
	min,
	max,
	step,
	textInputProps,
	className,
	onChange,
}) => {
	const [minValue, maxValue] = value;
	const [[localMinValue, localMaxValue], setLocalValues] = useState([
		minValue || min,
		maxValue || max,
	]);
	const [errors, setErrors] = useState({ min: false, max: false });

	const sanitizedOnChange = ([minRaw, maxRaw]) => {
		setErrors({ min: false, max: false });

		const nMin = Number(minRaw);
		const nMax = Number(maxRaw);

		// if any of the inputs are not a number, skip `onChange`-calls until it is fixed
		if (Number.isNaN(nMin)) {
			setErrors((e) => ({ ...e, min: true }));
			return;
		}

		if (Number.isNaN(nMax)) {
			setErrors((e) => ({ ...e, max: true }));
			return;
		}

		// if `nMin` & `nMax` are switched, indicate error but still call onChange
		if (nMin > nMax) {
			setErrors({ min: true, max: true });
		}

		// if the new minValue or maxValue would be out of bounds, skip calling `onChange`
		if (nMin < min || nMin > max) {
			setErrors((e) => ({ ...e, min: true }));
		}

		if (nMax < min || nMax > max) {
			setErrors((e) => ({ ...e, min: true }));
		}

		onChange([nMin, nMax]);
	};

	const handleLocalChange = (values) => {
		setLocalValues(values);
		sanitizedOnChange(values);
	};

	return (
		<div className={cn('flex flex-wrap justify-center', className)}>
			<div style={{ width: 'calc(50% - .875rem)' }}>
				<TextInput
					id="sliderMin"
					size="small"
					value={localMinValue}
					onChange={(e) => handleLocalChange([e.target.value, localMaxValue])}
					{...textInputProps}
					error={errors?.min}
				/>
			</div>
			<span className="w-1 h-1 mx-3 self-center rounded-full bg-ca-gray" />
			<div style={{ width: 'calc(50% - .875rem)' }}>
				<TextInput
					id="sliderMax"
					size="small"
					value={localMaxValue}
					onChange={(e) => handleLocalChange([localMinValue, e.target.value])}
					{...textInputProps}
					error={errors?.max}
				/>
			</div>
			<ReactSlider
				className="mt-4 w-full"
				min={min}
				max={max}
				step={step}
				marks={10}
				value={[localMinValue, localMaxValue]}
				renderTrack={DoubleTrack}
				renderThumb={Thumb}
				onChange={handleLocalChange}
			/>
		</div>
	);
};

Slider.propTypes = {
	value: PropTypes.arrayOf(PropTypes.number),
	min: PropTypes.number.isRequired,
	max: PropTypes.number.isRequired,
	step: PropTypes.number,
	textInputProps: PropTypes.shape({
		startAdornment: PropTypes.string,
		endAdornment: PropTypes.string,
	}),
	className: PropTypes.string,
	onChange: PropTypes.func,
};

Slider.defaultProps = {
	value: [0, 100],
	step: 1,
	textInputProps: {},
	className: '',
	onChange: () => { },
};

export default Slider;
