import IconBase from './IconBase';

const Cross: IconBase = ({ className = '' }) => (
	<svg
		className={className}
		viewBox="0 0 256 256"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M205.66 194.34a8 8 0 0 1-11.32 11.32L128 139.31l-66.34 66.35a8 8 0 0 1-11.32-11.32L116.69 128L50.34 61.66a8 8 0 0 1 11.32-11.32L128 116.69l66.34-66.35a8 8 0 0 1 11.32 11.32L139.31 128Z"
			fill="currentColor"
		/>
	</svg>
);

export default Cross;
