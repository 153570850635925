import cn from '../../utils/cn';
import PropTypes from 'prop-types';

import Tooltip from '../Tooltip/Tooltip';

const IconButton = ({
	icon: Icon,
	tooltip,
	cursor,
	className,
	disabled,
	onClick,
}) => {
	if (!Icon) {
		return null;
	}

	return (
		<Tooltip content={tooltip} placement="top-start">
			<span className="inline-flex">
				<button
					type="button"
					disabled={disabled}
					onClick={onClick}
					className={cn(
						'p-2 text-ca-gray transition-colors focus:outline-none focus-visible:ring-4 focus-visible:ring-opacity-10 focus-visible:ring-ca-purple focus-visible:text-ca-gray-a',
						!disabled && 'hover:text-ca-gray-a active:text-ca-gray-a',
						disabled && 'cursor-not-allowed text-ca-silver',
						cursor && cursor
					)}
				>
					<Icon className={className} />
				</button>
			</span>
		</Tooltip>
	);
};

IconButton.propTypes = {
	icon: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
	tooltip: PropTypes.string,
	cursor: PropTypes.string,
	className: PropTypes.string,
	disabled: PropTypes.bool,
	onClick: PropTypes.func,
};

IconButton.defaultProps = {
	onClick: () => { },
	className: 'h-5',
	disabled: false,
	tooltip: '',
	cursor: '',
};

export default IconButton;
