import FilterButton from 'components/Filter/FilterButton';
import FilterFieldMenu from 'components/Filter/FilterFieldMenu';
import FilterTag from 'components/Filter/FilterTag';
import { FilterValueMenu, useKeyPress, useOnClickOutside } from 'crunch-components';
import { useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { GET_INVENTORY_ALLOCATION_PRODUCT_FILTERS } from '../../../api/inventory-allocation-products';

const ProductsFilter = ({ filters, onChange, isReadOnly = false }) => {
	const ref = useRef();
	const [filtersVisible, setFiltersVisible] = useState(false);
	const [[selectedField, selectedType], setSelectedField] = useState([]);
	const { isLoading: isFiltersLoading, data = [] } = useQuery(
		'product-filters',
		GET_INVENTORY_ALLOCATION_PRODUCT_FILTERS,
		{
			staleTime: 5 * 60 * 1000,
		}
	);

	const handleFiltersClose = () => {
		setSelectedField([]);
		setFiltersVisible(false);
	};

	const handleFiltersOpen = () => {
		setSelectedField([]);
		setFiltersVisible(true);
	};

	useOnClickOutside(ref, handleFiltersClose);
	useKeyPress('Escape', handleFiltersClose);

	const handleFieldChange = (id, type) => {
		const filter = data.find((f) => f.id === id);
		setSelectedField([filter, type]);
	};

	const handleFilterChange = (value) => {
		onChange((f) => new Map(f.set(selectedField?.id, value)));
		handleFiltersClose();
	};

	const handleFilterRemove = (filter) => {
		onChange((f) => {
			const newMap = new Map(f);
			newMap.delete(filter);
			return newMap;
		});
		handleFiltersClose();
	};

	const handleFiltersReset = () => {
		onChange(new Map());
		handleFiltersClose();
	};

	const activeFilters = Array.from(filters.entries()).map(([id, val]) => {
		const filter = data.find((f) => f.id === id);

		let value = val;

		if (filter?.type === 'Range') {
			const [min, max] = val;

			if (filter?.percentage) {
				value = `${min * 100}% - ${max * 100}%`;
			} else {
				value = `${min} - ${max}`;
			}
		}

		return {
			id: filter?.id,
			name: filter?.name,
			value,
		};
	});

	// filter out currently active filters
	const availableFilters = data.filter(
		(f) => !activeFilters.map(({ id }) => id).includes(f.id)
	);

	if (data.length === 0) {
		return null;
	}

	return (
		<div className="flex justify-between items-center">
			<div className="flex items-center" ref={ref}>
				<div className="relative mr-3">
					{!isReadOnly && (
						<FilterButton
							disabled={!!activeFilters.length && !availableFilters.length}
							onClick={handleFiltersOpen}
						/>
					)}
					<FilterFieldMenu
						options={availableFilters}
						loading={isFiltersLoading}
						visible={filtersVisible}
						onChange={(fieldId) => handleFieldChange(fieldId, 'menu')}
						onReset={activeFilters.length ? handleFiltersReset : null}
					/>
					<FilterValueMenu
						key={selectedField?.id}
						id={selectedField?.id}
						field={selectedField?.name || ''}
						value={filters.get(selectedField?.id) || ''}
						options={
							selectedField?.checklist_options ||
							selectedField?.list_options ||
							selectedField?.range_options
						}
						type={selectedField?.type}
						percentage={selectedField?.percentage}
						// only show dropdown if there is an active field, and the type is 'menu'
						visible={!!selectedField && selectedType === 'menu'}
						onApply={handleFilterChange}
						onCancel={handleFiltersClose}
						className="left-full ml-60"
					/>
				</div>
				<div className="flex flex-wrap gap-3">
					{activeFilters.map((f) => (
						<div key={f.id} className="relative inline">
							<FilterTag
								onClick={() => {
									setFiltersVisible(false);
									handleFieldChange(f.id, 'tag');
								}}
								field={f.name}
								value={f.value}
							/>
							<FilterValueMenu
								key={selectedField?.id}
								field={selectedField?.name || ''}
								value={filters.get(selectedField?.id) || ''}
								options={
									selectedField?.checklist_options ||
									selectedField?.list_options ||
									selectedField?.range_options
								}
								type={selectedField?.type}
								percentage={selectedField?.percentage}
								visible={
									// only show dropdown if currently active field is this one, and the type is 'tag'
									selectedField?.id === f.id && selectedType === 'tag'
								}
								onApply={handleFilterChange}
								onRemove={() => handleFilterRemove(f.id)}
								className="left-0"
							/>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default ProductsFilter;
