import HeaderNav from 'components/HeaderNav/HeaderNav';
import { FunctionComponent } from 'react';

export interface TabLink {
	id: string;
	label: string;
	to: string;
}

interface Props {
	tabs: TabLink[];
}

const Tabs: FunctionComponent<Props> = ({ tabs }) => {
	return <HeaderNav items={tabs} />;
};

export default Tabs;
