import clsx from 'clsx';
import { CircularProgress, PlusIcon } from 'crunch-components';
import { FunctionComponent } from 'react';
import { useParams } from 'react-router-dom';
import DraftTab, { DraftTabLink } from './DraftTab';

interface Props {
	tabs: DraftTabLink[];
	currentTab: string;
	addDraft: () => void;
	isAddingDraft?: boolean;
}

const DraftTabs: FunctionComponent<Props> = ({
	tabs,
	addDraft,
	currentTab,
	isAddingDraft,
}) => {
	const { draftId } = useParams();

	return (
		<div className="w-[calc(100%+5rem)] flex border-b border-ca-silver mt-8 gap-2 -mx-10 pl-10 overflow-x-scroll max-w-4/5">
			{tabs.map((tab) => (
				<DraftTab
					key={tab.id}
					{...tab}
					to={`${tab.to}/${currentTab || 'results'}`}
					isActive={draftId === tab.id}
				/>
			))}
			<button className="inline" onClick={addDraft} disabled={isAddingDraft}>
				<span
					className={clsx(
						'border border-ca-silver border-b-0 bg-opacity-30 bg-white py-2 pl-4 pr-8 font-bold rounded-t-md flex items-center justify-between gap-2 whitespace-nowrap',
						isAddingDraft ? 'text-ca-gray' : 'text-ca-purple',
					)}
				>
					{isAddingDraft ? (
						<CircularProgress size="text" />
					) : (
						<PlusIcon className="h-3" />
					)}{' '}
					{isAddingDraft ? 'Adding draft' : 'Add draft'}
				</span>
			</button>
		</div>
	);
};

export default DraftTabs;
