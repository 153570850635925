import { Helmet } from 'react-helmet-async';
import { Route, Routes } from 'react-router-dom';

import { AuthGuard } from 'crunch-components';
import DraftReport from 'pages/inventoryAllocation/views/DraftReport';
import InventoryAllocationView from 'pages/inventoryAllocation/views/InventoryAllocationView';
import { DEFAULT_PATHS } from 'utils/routerUtils';
import InventoryAllocationsView from './pages/inventoryAllocations/views/InventoryAllocations';

const EnabledRoutes = () => {
	return (
		<Routes>
			<Route
				element={
					<AuthGuard
						accessibleBy="private-only"
						publicHomePath={DEFAULT_PATHS['public']}
					/>
				}
			>
				<Route
					path="inventory-allocations"
					element={
						<>
							<Helmet title="Crunch Platform | Vulcan" />
							<InventoryAllocationsView />
						</>
					}
				/>
				<Route
					path="inventory-allocations/:inventoryAllocationId"
					element={<InventoryAllocationView />}
				>
					<Route path=":draftId/*" element={<DraftReport />} />
				</Route>
			</Route>
		</Routes>
	);
};
export default EnabledRoutes;
