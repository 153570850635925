import { Helmet, HelmetProvider } from 'react-helmet-async';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import {
	cn,
	ModalProvider,
	SmartHomeRedirect,
	ToastQueue,
} from 'crunch-components';
import { ReactQueryDevtools } from 'react-query/devtools';

import VulcanQueryClientProvider from 'contexts/VulcanQueryClientProvider';
import EnabledRoutes from 'EnabledRoutes';
import PageWithSidebar from 'layouts/PageWithSidebar';
import Vulcan404 from 'pages/vulcan404/Vulcan404';
import { DEFAULT_PATHS } from 'utils/routerUtils';
import LoginView from './pages/login/views/Login';

const App = () => {
	return (
		<HelmetProvider>
			<VulcanQueryClientProvider>
				{process.env.NODE_ENV === 'development' && (
					<ReactQueryDevtools position="bottom-right" />
				)}
				<BrowserRouter>
					<ModalProvider>
						<div
							className={cn(
								'bg-ca-ghost-white min-h-screen',
								process.env.NODE_ENV === 'development'
									? // quickly identify which text is missing a color definition in DEV-mode
										'text-red-600'
									: 'text-ca-black',
							)}
						>
							<Routes>
								<Route
									path="*"
									element={<SmartHomeRedirect homePaths={DEFAULT_PATHS} />}
								/>

								<Route path="/login" element={<LoginView />}></Route>
								<Route path="/stock" element={<PageWithSidebar />}>
									<Route path="*" element={<EnabledRoutes />} />
								</Route>
								<Route
									path="/page-not-found"
									Component={() => (
										<>
											<Helmet title="Crunch Platform | Page not found" />
											<Vulcan404 />
										</>
									)}
								/>
							</Routes>
						</div>
					</ModalProvider>
				</BrowserRouter>
				<ToastQueue />
			</VulcanQueryClientProvider>
		</HelmetProvider>
	);
};

export default App;
