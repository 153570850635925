/* eslint-disable react/jsx-no-bind */
import { FunctionComponent } from 'react';

import { Button, CircularProgress } from 'crunch-components';
import { Configuration } from '../../../shared/models/configuration';
import { InventoryAllocation } from '../../../shared/models/inventoryAllocation';
import { InventoryAllocationReport } from '../../../shared/models/inventoryAllocationReport';
import ConstraintsWizardSection from '../components/ConstraintsWizardSection';
import MarketingInfluenceWizardSection from '../components/MarketingInfluenceWizardSection';
import ScopeWizardSection from '../components/ScopeWizardSection';
import SecondaryObjectivesWizardSection from '../components/SecondaryObjectivesWizardSection';

interface Props extends Configuration {
	inventoryAllocationId: InventoryAllocation['id'];
	reportId: InventoryAllocationReport['id'];
	defaultFilters: any[];
	isInProgress?: boolean;
	isProposal?: boolean;
	isOutdated?: boolean;
	isCopyingConfiguration?: boolean;
	isAlgorithmRunning?: boolean;
	onCopyConfiguration: () => void;
	onStartAlgorithm: () => void;
}

const InventoryAllocationReportConfiguration: FunctionComponent<Props> = ({
	onCopyConfiguration,
	onStartAlgorithm,
	isInProgress,
	isProposal,
	isOutdated,
	isCopyingConfiguration,
	isAlgorithmRunning,
	inventoryAllocationId,
	reportId,
	defaultFilters,
	...configuration
}) => {
	return (
		<div>
			<p className="text-lg text-ca-gray">
				Optimize <strong className="text-ca-purple">revenue</strong> by
				allocating inventory.
			</p>
			<ScopeWizardSection
				{...configuration.scope}
				defaultFilters={defaultFilters}
				inventoryAllocationId={inventoryAllocationId}
				reportId={reportId}
			/>
			<ConstraintsWizardSection
				inventoryAllocationId={inventoryAllocationId}
				reportId={reportId}
				constraints={configuration.constraints}
				isInProgress={isInProgress}
				isProposal={isProposal}
			/>
			{window._ENV_.REACT_APP_SECONDARY_OBJECTIVES_ENABLED && (
				<SecondaryObjectivesWizardSection
					inventoryAllocationId={inventoryAllocationId}
					reportId={reportId}
					secondaryObjectives={configuration.secondaryObjectives}
					isInProgress={isInProgress}
					isProposal={isProposal}
				/>
			)}
			{window._ENV_.REACT_APP_MARKETING_ACTIONS_ENABLED && (
				<MarketingInfluenceWizardSection
					inventoryAllocationId={inventoryAllocationId}
					reportId={reportId}
					marketingExpectations={configuration.marketingExpectations}
				/>
			)}
			{isOutdated ? (
				<Button onClick={onStartAlgorithm} disabled={isAlgorithmRunning}>
					{!isAlgorithmRunning && <span>Calculate optimal allocations</span>}
					{isAlgorithmRunning && (
						<CircularProgress size="text" className="text-ca-ghost-white" />
					)}
				</Button>
			) : (
				<Button onClick={onCopyConfiguration} disabled={isCopyingConfiguration}>
					{isCopyingConfiguration
						? 'Creating new draft...'
						: 'Copy configuration to new draft'}
				</Button>
			)}
		</div>
	);
};

export default InventoryAllocationReportConfiguration;
