import { Title } from 'crunch-components';
import { FunctionComponent } from 'react';
import {
	Control,
	FieldArrayWithId,
	UseFieldArrayReturn,
} from 'react-hook-form';
import { Configuration, Scope } from '../../../shared/models/configuration';
import { InventoryAllocation } from '../../../shared/models/inventoryAllocation';
import { InventoryAllocationReport } from '../../../shared/models/inventoryAllocationReport';
import AddMarketingExpectation from './AddMarketingExpectation';
import MarketingExpectationFieldset from './MarketingExpectationFieldset';
import { NoMarketingExpectations } from './MarketingExpectationOption';

interface Props {
	inventoryAllocationId: InventoryAllocation['id'];
	reportId: InventoryAllocationReport['id'];
	fields: FieldArrayWithId<Configuration, 'marketingExpectations', 'id'>[];
	append: UseFieldArrayReturn<Configuration, 'marketingExpectations'>['append'];
	remove: UseFieldArrayReturn['remove'];
	control: Control<Configuration>;
	onChange: (scope: Scope, index: number) => void;
}

const MarketingExpectationsFieldset: FunctionComponent<Props> = ({
	inventoryAllocationId,
	reportId,
	append,
	control,
	fields,
	remove,
	onChange,
}) => {
	return (
		<div className="my-16">
			<Title type="section">Marketing influence</Title>
			<p className="text-ca-gray max-w-lg pt-2 pb-8">
				Marketing campaigns might influence the prospected sales. This section
				allows you to estimate the effect of those campaigns.
			</p>
			<div className="flex flex-col gap-2">
				{fields.length === 0 && <NoMarketingExpectations />}
				{fields.map((field, index) => (
					<MarketingExpectationFieldset
						inventoryAllocationId={inventoryAllocationId}
						reportId={reportId}
						key={field.id}
						marketingExpectation={field}
						index={index}
						remove={remove}
						control={control}
						onChange={(scope: Scope) => {
							onChange(scope, index);
						}}
					/>
				))}
				<AddMarketingExpectation append={append} />
			</div>
		</div>
	);
};

export default MarketingExpectationsFieldset;
