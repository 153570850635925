import cn from '../../utils/cn';
import PropTypes from 'prop-types';

const Conditional = ({ label, children, className }) => (
	<div className={cn('relative border-l-2 border-ca-gray', className)}>
		<span className="absolute -left-px top-0 py-2 px-3 rounded rounded-l-none font-bold leading-none text-xxs bg-ca-gray text-white">
			{label}
		</span>
		<div className="pl-5 pt-9">{children}</div>
	</div>
);

Conditional.propTypes = {
	label: PropTypes.node.isRequired,
	children: PropTypes.node.isRequired,
};

export default Conditional;
