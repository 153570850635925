import cn from '../../utils/cn';
import { PropsWithChildren } from 'react';

const InputWithLabel = ({
	label,
	htmlFor,
	children,
	labelPosition = 'same-line',
	className = '',
	labelClassName = '',
}: PropsWithChildren<{
	label: React.ReactNode;
	htmlFor: string;
	labelPosition?: 'same-line' | 'label-above-input';
	className?: string;
	labelClassName?: string;
}>) => (
	<div
		className={cn(
			'text-ca-gray focus-within:text-ca-black',
			labelPosition === 'same-line' && 'flex items-center',
			labelPosition === 'label-above-input' && 'flex flex-col gap-1 -ml-1',
			className
		)}
	>
		<p
			className={cn(
				'text-sm',
				labelClassName,
				cn(labelPosition === 'label-above-input' && 'ml-1')
			)}
		>
			<label htmlFor={htmlFor}>{label}</label>
		</p>
		{children}
	</div>
);

export default InputWithLabel;
