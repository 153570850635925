import { cn } from 'crunch-components';
import { NavLink } from 'react-router-dom';

interface Props {
	items: Array<{
		id: string;
		to: string;
		label: string;
	}>;
}

/**
 * ~~ As of 20 sept 2024 ~~
 * This is not a shared component between stratos & vulcan because of different versions of react-router-dom
 */
function HeaderNav({ items }: Props) {
	return (
		<div className="w-full flex border-b border-ca-silver gap-10 py-1 ">
			{items.map((item) => (
				<NavLink
					key={item.id}
					to={item.to}
					className={({isActive}) =>
						cn(
							'py-2 hover:text-ca-purple font-semibold transition-colors border-b -mb-[5px]',
							isActive === true
								? 'text-ca-purple  border-b-ca-purple'
								: 'text-ca-gray border-b-transparent',
						)
					}
				>
					{item.label}
				</NavLink>
			))}
		</div>
	);
}

export default HeaderNav;
