import { Button, CircularProgress, PlusIcon } from 'crunch-components';
import { FunctionComponent } from 'react';

interface Props {
	addDraft: () => void;
	isAddingDraft?: boolean;
}

const InventoryAllocationReportInProgress: FunctionComponent<Props> = ({
	addDraft,
	isAddingDraft,
}) => (
	<div>
		<p className="text-ca-gray mt-10">Numbers are being crunched...</p>
		<p className="text-ca-gray">
			This may take a while, but there is no need to wait.
		</p>
		<p className="text-ca-gray mb-10">
			You can configure another draft report in the meantime.
		</p>
		<Button className="flex gap-3" onClick={addDraft} disabled={isAddingDraft}>
			{isAddingDraft ? (
				<CircularProgress size="text" />
			) : (
				<PlusIcon className="h-3" />
			)}
			{isAddingDraft ? 'Adding draft' : 'Add draft'}
		</Button>
	</div>
);

export default InventoryAllocationReportInProgress;
