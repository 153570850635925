import { Title, useModal } from 'crunch-components';
import { FunctionComponent, useCallback } from 'react';
import { Scope } from '../../../shared/models/configuration';
import { InventoryAllocation } from '../../../shared/models/inventoryAllocation';
import { InventoryAllocationReport } from '../../../shared/models/inventoryAllocationReport';
import { ProductDTO, StockLocationDTO } from '../../../shared/models/schema';
import LocationsTableModal from '../modals/LocationsTableModal';
import ProductsTableModal from '../modals/ProductsTableModal';
import {
	getLocationsScopeDescription,
	getProductsScopeDescription,
} from './ScopeDescription';

interface Props {
	inventoryAllocationId: InventoryAllocation['id'];
	reportId: InventoryAllocationReport['id'];
	value: Scope;
	defaultFilters: any[];
	onChange: (scope: Scope) => void;
}

const ScopeFieldset: FunctionComponent<Props> = ({
	inventoryAllocationId,
	reportId,
	value,
	defaultFilters,
	onChange,
}) => {
	const { open } = useModal();

	const onSelectedProductsChange = (products: ProductDTO['id'][]): void => {
		onChange({ ...value, products });
	};

	const onSelectedLocationsChange = (
		locations: StockLocationDTO['stock_location_id'][]
	): void => {
		onChange({ ...value, locations });
	};

	const openProductsModal = useCallback((): void => {
		open({
			modalNode: (
				<ProductsTableModal
					inventoryAllocationId={inventoryAllocationId}
					reportId={reportId}
					selectedProducts={value.products}
					defaultFilters={defaultFilters}
					setSelectedProducts={onSelectedProductsChange}
				/>
			),
			sizeClass: 'w-4/5',
		});
	}, [value.products]);

	const openLocationsModal = (): void => {
		open({
			modalNode: (
				<LocationsTableModal
					inventoryAllocationId={inventoryAllocationId}
					reportId={reportId}
					selectedLocations={value.locations}
					setSelectedLocations={onSelectedLocationsChange}
				/>
			),
			sizeClass: 'w-4/5',
		});
	};

	return (
		<div className="my-16">
			<Title type="section">Scope</Title>
			<p className="text-ca-gray max-w-lg pt-2 pb-8">
				The scope determines which products and locations will be included in
				the inventory allocation.
			</p>
			<div className="border-ca-silver border rounded-lg bg-white px-4 py-5 -ml-4 max-w-xl">
				<p className="text-ca-gray">
					Calculate the optimal inventory allocation for{' '}
					<button type="button" onClick={openProductsModal}>
						<strong className="text-ca-purple">
							{getProductsScopeDescription(value.products)}
						</strong>
					</button>{' '}
					over{' '}
					<button type="button" onClick={openLocationsModal}>
						<strong className="text-ca-purple">
							{getLocationsScopeDescription(value.locations)}
						</strong>
					</button>
					.
				</p>
			</div>
		</div>
	);
};

export default ScopeFieldset;
