import { Title } from 'crunch-components';
import { FunctionComponent } from 'react';
import {
	Control,
	FieldArrayWithId,
	UseFieldArrayReturn,
} from 'react-hook-form';
import { Configuration } from '../../../shared/models/configuration';
import { InventoryAllocation } from '../../../shared/models/inventoryAllocation';
import { InventoryAllocationReport } from '../../../shared/models/inventoryAllocationReport';
import ConstraintFieldset from './ConstraintFieldset';
import { NoConstraints } from './ConstraintOption';
import ConstraintPicker from './ConstraintPicker';

interface Props {
	inventoryAllocationId: InventoryAllocation['id'];
	reportId: InventoryAllocationReport['id'];
	fields: FieldArrayWithId<Configuration, 'constraints', 'id'>[];
	append: UseFieldArrayReturn<Configuration, 'constraints'>['append'];
	remove: UseFieldArrayReturn['remove'];
	control: Control<Configuration>;
}

const ConstraintsFieldset: FunctionComponent<Props> = ({
	inventoryAllocationId,
	reportId,
	fields,
	append,
	remove,
	control,
}) => {
	return (
		<div className="my-16">
			<Title type="section">Constraints</Title>
			<p className="text-ca-gray max-w-lg pt-2 pb-6">
				Constraints are requirements which should be met. Breaking these
				constraints would render the solution infeasible.
			</p>
			<div className="flex flex-col gap-2">
				{fields.length === 0 && <NoConstraints />}
				{fields.map((field, index) => (
					<ConstraintFieldset
						inventoryAllocationId={inventoryAllocationId}
						reportId={reportId}
						key={field.id}
						constraint={field}
						control={control}
						index={index}
						remove={remove}
					/>
				))}
				<ConstraintPicker
					append={append}
					configuredTypes={fields.map(({ type }) => type)}
				/>
			</div>
		</div>
	);
};

export default ConstraintsFieldset;
