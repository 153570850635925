import cn from '../../utils/cn';
import { useRef, useState } from 'react';
import DotsVertical from '../Icons/DotsVertical';
import { Popover, PopoverContent, PopoverTrigger } from '../Popover/Popover';
import Text from '../Text/Text';

type PrefabOptionInfo = {
	label: string;
	Icon: React.ComponentType<{ className?: string }>;
	onClick: () => void;
	disabled?: boolean;
	hasSafety?: boolean;
};

const isPrefabOption = (toVerify: {}): toVerify is PrefabOptionInfo => {
	const narrowed = toVerify as PrefabOptionInfo;
	return (
		narrowed.Icon !== undefined &&
		narrowed.label !== undefined &&
		narrowed.onClick !== undefined
	);
};

type KebabMenuProps = {
	closeDelayMs?: number;
	options: Array<(() => JSX.Element) | PrefabOptionInfo>;
};

const KebabMenu = (props: KebabMenuProps) => {
	const defaultedCloseDelayMs = props.closeDelayMs ?? 300;
	const timer = useRef<ReturnType<typeof setTimeout> | null>(null);
	const [isOpen, setIsOpen] = useState(false);

	const handleMouseEnter = () => {
		if (!timer.current) {
			return;
		}
		clearTimeout(timer.current);
		timer.current = null;
	};

	const handleMouseLeave = () => {
		timer.current = setTimeout(() => {
			setIsOpen(false);
			timer.current = null;
		}, defaultedCloseDelayMs);
	};

	return (
		<Popover open={isOpen}>
			<PopoverTrigger
				onMouseEnter={handleMouseEnter}
				onMouseLeave={handleMouseLeave}
				onClick={(e) => {
					e.stopPropagation();
					setIsOpen((old) => !old);
				}}
				className={cn(
					'border rounded transition-all px-2 py-2 hover:bg-ca-purple-bg-2 active:bg-ca-purple-bg',
					isOpen
						? 'ring-ca-purple ring-4 ring-opacity-10 border-ca-purple'
						: 'ring-0 ring-opacity-0 border border-transparent',
				)}
			>
				<DotsVertical className="h-5 w-5" />
			</PopoverTrigger>
			<PopoverContent
				unstyled
				align="start"
				side="right"
				className="border border-gray-200 rounded-lg shadow-ca bg-white min-w-[164px] overflow-hidden"
				onMouseEnter={handleMouseEnter}
				onMouseLeave={handleMouseLeave}
				onClick={(e) => {
					e.stopPropagation();
				}}
			>
				{props.options.map((option) => {
					return isPrefabOption(option) ? (
						<PrefabOption
							key={option.label}
							{...option}
							setIsOpen={setIsOpen}
							hasSafety={option.hasSafety}
						/>
					) : (
						option()
					);
				})}
			</PopoverContent>
		</Popover>
	);
};

export default KebabMenu;

type PrefabOptionProps = PrefabOptionInfo & {
	setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const PrefabOption = (props: PrefabOptionProps) => {
	const [safteyOn, setSafetyOn] = useState(props.hasSafety ?? false);

	const { Icon } = props;

	const baseOptionElement = (
		<div
			className="flex gap-2 items-center hover:bg-gray-100 px-4 py-2 transition-colors bg-transparent"
			role="button"
			tabIndex={0}
			onKeyDown={(e) => {
				if (e.key !== 'Enter' || props.disabled) {
					return;
				}
				if (props.hasSafety) {
					setSafetyOn(false);
					return;
				}
				props.setIsOpen((old) => !old);
				props.onClick();
			}}
			onClick={() => {
				if (props.disabled) {
					return;
				}

				if (props.hasSafety) {
					setSafetyOn(false);
					return;
				}
				props.setIsOpen((old) => !old);
				props.onClick();
			}}
		>
			<Icon
				className={cn(
					props.disabled ? 'text-ca-gray-400' : 'text-ca-gray',
					'mr-2 block min-w-[14px] max-w-[14px]',
				)}
			/>
			<Text type="secondary" size="text-base">
				{props.label}
			</Text>
		</div>
	);

	return props.hasSafety ? (
		<div className="relative" key={props.label}>
			{baseOptionElement}
			<div
				className={cn(
					'absolute transition-all top-0 overflow-hidden duration-300 ease-out',
					safteyOn ? 'right-full w-0' : 'right-0  w-full',
				)}
			>
				<div
					className={cn(
						'flex gap-2 items-center px-4 py-2  bg-ca-destructive whitespace-nowrap transition-all',
					)}
					tabIndex={0}
					role="button"
					onKeyDown={(e) => {
						if (e.key !== 'Enter') {
							return;
						}
						props.onClick();
						props.setIsOpen(false);
					}}
					onClick={() => {
						props.onClick();
						props.setIsOpen(false);
					}}
				>
					<Icon className="mr-2 block min-w-[14px] max-w-[14px] text-white" />
					<Text type="inverted" size="text-base">
						Are you sure?
					</Text>
				</div>
			</div>
		</div>
	) : (
		baseOptionElement
	);
};
